<template>
  <v-container class="px-lg-10 full-height">
    <v-timeline
      class="full-height"
      v-if="isLargeScreen"
      ref="targetInfiniteDiv"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="busy">
      <v-timeline-item
        v-for="(item, index) in timelines"
        :key="index"
        :icon="icons[item.type]"
        fill-dot>
        <HomeCard :item="item" :icon="icons[item.type]"/>
      </v-timeline-item>
    </v-timeline>
    <div
      v-else
      ref="targetInfiniteDiv"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="busy">
      <div
        v-for="(item, index) in timelines"
        :key="index">
        <HomeCard
          :icon="icons[item.type]"
          fill-dot
          :item="item">
        </HomeCard>
        <v-divider class="my-4"></v-divider>
      </div>
    </div>
    <ScrollLoding :loding="busy"></ScrollLoding>
  </v-container>
</template>

<script>
import HomeCard from '@/components/card/home'
import ScrollLoding from '@/components/base/scrollLoding'

export default {
  name: 'Home',
  props: {
    item: {
      default: ''
    }
  },
  components: {
    ScrollLoding,
    HomeCard
  },
  data: () => ({
    model: null,
    icons: {
      blog: 'fa-pencil',
      book: 'fa-book',
      video: 'fa-youtube-play',
      game: 'fa-gamepad',
      sentence: 'fa-comment',
      repo: 'fa-github'
    },
    timelines: [],
    limit: 7,
    offset: 1,
    busy: false,
    count: 0,
    items: [
      { type: 'blog', createdAt: '2020-02-15', img: '/imgs/blog/vue-live2d.PNG', title: 'vue-live2d', desc: 'vue 插件' },
      { type: 'book', createdAt: '2020-02-15', img: '/imgs/book/10546125.jpg', title: '《JavaScript高级程序》笔记', desc: 'DOM 与 BOM' },
      { type: 'video', createdAt: '2020-02-15', title: '《梅子鸡之味》', desc: '' },
      { type: 'game', createdAt: '2020-02-15', img: '/imgs/game/292030.jpg', title: '《巫师3》', desc: '一个世界' },
      { type: 'sentence', createdAt: '2020-02-15', title: '吓吓，有被谢到', desc: '' },
      { type: 'blog', createdAt: '2020-02-15', title: 'bilibili 视频下载', desc: '油猴插件' }
    ]
  }),

  computed: {
    isLargeScreen () {
      return this.$vuetify.breakpoint.lgAndUp
    },
    isAllData () {
      return this.timelines.length >= this.count
    }
  },

  async created () {
    await this.initData()
  },

  methods: {
    async initData () {
      await this.listTimelines()

      const dom = this.isLargeScreen
        ? this.$refs.targetInfiniteDiv.$el
        : this.$refs.targetInfiniteDiv
      const coverHeight = dom.clientHeight
      let hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      while (!this.isAllData && !hasCover) {
        await this.listTimelines()
        hasCover = dom.lastChild.offsetTop + dom.lastChild.clientHeight >= coverHeight
      }
    },

    async loadMore () {
      if (this.isAllData) {
        return
      }
      this.busy = true
      await this.listTimelines()
      this.busy = false
    },

    async listTimelines () {
      const { count, rows } = await this.$http.Timeline.list({
        limit: this.limit,
        offset: this.offset++
      })
      this.count = count
      this.timelines.push(...rows)
    },

    getMoreInfo () {
      this.$dialog.message.info('待补充此功能 (●ˇ∀ˇ●)', {
        position: 'top',
        timeout: 1500
      })
    }
  }
}
</script>

<style>

</style>
