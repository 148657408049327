<template>
  <SentenceCard v-if="$store.state.entityType.sentence == item.type" :sentence="item.instance"></SentenceCard>
  <BlogCard v-else-if="$store.state.entityType.blog == item.type" :blog="item.instance"></BlogCard>
</template>

<script>
import BlogCard from '@/components/Blog/BlogCard'
import SentenceCard from '@/components/Sentence/SentenceCard.vue'

export default {
  name: 'HomeCard',
  components: {
    BlogCard,
    SentenceCard
  },
  props: {
    item: Object,
    icon: String
  },
  data: () => ({

  }),

  created () {
  },

  methods: {
    copy () {
      this.$copyText(this.item.content).then(e => {
        this.$dialog.message.success('复制成功', {
          position: 'top-right',
          timeout: 500
        })
      }, (e) => {
        this.$dialog.message.error(`复制失败：${e}`, {
          position: 'top-right',
          timeout: 1000
        })
      })
    }
  }
}
</script>
