<template>
  <article>
    <div class="article-info">
      <p class="article-title hasBeforeIcon mb-0" :title="sentence.content">
        {{ sentence.content }}
      </p>
      <div class="d-flex justify-space-between mt-1 text-subtitle-3">
        <p class="mb-0 font-weight-regular" style="padding-top: 2px; padding-bottom: 2px">{{ this.createdDateText }}</p>
        <v-tooltip bottom v-if="sentence.isQuote">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small text class="custom-transform-class text-none" @click="handleClick" v-bind="attrs" v-on="on">
              @{{ sentence.originAuthor }}
            </v-btn>
          </template>
          <span>{{ sentence.originUrl }}</span>
        </v-tooltip>
      </div>
    </div>

    <CategoryAndLabelCard
      :Category="sentence.Category"
      :Labels="sentence.Labels"
      @toQueryCategory="toQueryCategory"
      @toQueryLabel="toBeAdd">
    </CategoryAndLabelCard>
  </article>
</template>

<script>
import CategoryAndLabelCard from '@/components/base/CategoryAndLabelCard'

export default {
  name: 'SentenceCard',
  components: {
    CategoryAndLabelCard
  },
  props: {
    sentence: {
      default: ''
    }
  },
  computed: {
    createdDateText () {
      const date = this.sentence.createdAt.split(' ')
      const day = date[0].split('-')
      return `${day[0]}年${day[1]}月${day[2]}日 ${date[1]}`
    }
  },
  data () {
    return {

    }
  },
  methods: {
    handleClick () {
      const url = this.sentence.originUrl
      const isUrl = this.$util.reg.isUrl(url)
      if (isUrl) {
        this.$util.openUrl(url)
      }
    },

    toQueryCategory (categoryId) {
      this.$router.push({
        name: 'sentence_index',
        query: {
          category_id: categoryId
        }
      })
    },

    toBeAdd () {
      this.$dialog.message.info('待补充此功能 (●ˇ∀ˇ●)', {
        position: 'top',
        timeout: 1500
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  article {
    .article-title {
      &:before {
        color: #1976d2;
        content: "\f075";
      }
    }
  }
</style>
